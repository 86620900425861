define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo, lang) {
  // module:
  //		dojo/regexp

  var regexp = {
    // summary:
    //		Regular expressions and Builder resources
  };
  lang.setObject("dojo.regexp", regexp);
  regexp.escapeString = function ( /*String*/str, /*String?*/except) {
    // summary:
    //		Adds escape sequences for special characters in regular expressions
    // except:
    //		a String with special characters to be left unescaped

    return str.replace(/([\.$?*|{}\(\)\[\]\\\/\+\-^])/g, function (ch) {
      if (except && except.indexOf(ch) != -1) {
        return ch;
      }
      return "\\" + ch;
    }); // String
  };
  regexp.buildGroupRE = function ( /*Object|Array*/arr, /*Function*/re, /*Boolean?*/nonCapture) {
    // summary:
    //		Builds a regular expression that groups subexpressions
    // description:
    //		A utility function used by some of the RE generators. The
    //		subexpressions are constructed by the function, re, in the second
    //		parameter.  re builds one subexpression for each elem in the array
    //		a, in the first parameter. Returns a string for a regular
    //		expression that groups all the subexpressions.
    // arr:
    //		A single value or an array of values.
    // re:
    //		A function. Takes one parameter and converts it to a regular
    //		expression.
    // nonCapture:
    //		If true, uses non-capturing match, otherwise matches are retained
    //		by regular expression. Defaults to false

    // case 1: a is a single value.
    if (!(arr instanceof Array)) {
      return re(arr); // String
    }

    // case 2: a is an array
    var b = [];
    for (var i = 0; i < arr.length; i++) {
      // convert each elem to a RE
      b.push(re(arr[i]));
    }

    // join the REs as alternatives in a RE group.
    return regexp.group(b.join("|"), nonCapture); // String
  };
  regexp.group = function ( /*String*/expression, /*Boolean?*/nonCapture) {
    // summary:
    //		adds group match to expression
    // nonCapture:
    //		If true, uses non-capturing match, otherwise matches are retained
    //		by regular expression.
    return "(" + (nonCapture ? "?:" : "") + expression + ")"; // String
  };
  return regexp;
});